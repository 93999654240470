import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/layout';
Vue.use(Router);
const routes = [{
  path: '/',
  component: Layout,
  redirect: '/channel',
  children: [{
    path: '',
    component: () => import('@/views/channel/index'),
    meta: {
      title: 'routes.channel',
      permissions: ['admin', 'service']
    }
  }]
}, {
  path: '/product',
  component: Layout,
  redirect: '/product',
  children: [{
    path: '',
    component: () => import('@/views/product/index'),
    meta: {
      title: 'routes.product',
      permissions: ['admin', 'service']
    }
  }]
}, {
  path: '/price',
  component: Layout,
  redirect: '/price',
  children: [{
    path: '',
    component: () => import('@/views/price/index'),
    meta: {
      title: 'routes.price',
      permissions: ['admin', 'service']
    }
  }]
}, {
  path: '/trade',
  component: Layout,
  redirect: '/trade',
  children: [{
    path: '',
    component: () => import('@/views/trade/index'),
    meta: {
      title: 'routes.trade'
    }
  }]
}, {
  path: '/user',
  component: Layout,
  redirect: '/user',
  children: [{
    path: '',
    component: () => import('@/views/user/index'),
    meta: {
      title: 'routes.user',
      permissions: ['admin']
    }
  }]
}, {
  path: '/role',
  component: Layout,
  redirect: '/role',
  children: [{
    path: '',
    component: () => import('@/views/role/index'),
    meta: {
      title: 'routes.role',
      permissions: ['admin']
    }
  }]
}, {
  path: '/address',
  component: Layout,
  redirect: '/address',
  children: [{
    path: '',
    component: () => import('@/views/address/index'),
    meta: {
      title: 'routes.address',
      permissions: ['admin', 'service']
    }
  }]
}, {
  path: '/stat',
  component: Layout,
  redirect: '/stat',
  children: [{
    path: '',
    component: () => import('@/views/stat/index'),
    meta: {
      title: 'routes.stat',
      permissions: ['admin']
    }
  }]
}];

// 404 page must be placed at the end !!!
routes.push({
  path: '*',
  redirect: '/404',
  hidden: true
});
export const asyncRoutes = routes;

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [{
  path: '/login',
  component: () => import('@/views/login/index'),
  hidden: true
}, {
  path: '/404',
  component: () => import('@/views/404'),
  hidden: true
}];
const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
});
const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;