import Vue from 'vue';
import VueLazyLoad from 'vue-lazyload';

// import 'normalize.css/normalize.css'
import ElementUI from 'element-ui';
import '@/styles/index.scss'; // global css
import 'element-ui/lib/theme-chalk/index.css';
import i18n from '@/i18n/index';
import echarts from 'echarts';
import App from './App.vue';
import store from './store';
import router from './router';
import '@/icons'; // icon
import '@/permission'; // permission control

// Vue.use(ElementUI, { locale });
Vue.use(ElementUI);
Vue.use(VueLazyLoad);
Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts;
const vm = new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app');
window.vue = vm;