var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "navbar",
    staticStyle: {
      "padding-left": "20px"
    }
  }, [_vm.$store.getters.device == "mobile" ? _c("hamburger", {
    staticClass: "hamburger-container",
    attrs: {
      "is-active": _vm.sidebar.opened
    },
    on: {
      toggleClick: _vm.toggleSideBar
    }
  }) : _vm._e(), _c("breadcrumb", {
    staticClass: "breadcrumb-container"
  }), _c("div", {
    staticClass: "right-menu"
  }, [_c("el-dropdown", {
    staticClass: "avatar-container",
    attrs: {
      trigger: "click"
    }
  }, [_c("div", {
    staticClass: "avatar-wrapper"
  }, [_c("div", [_vm._v(_vm._s(_vm.$i18n.locale == "zh" ? _vm.name : _vm.en_name))]), _c("i", {
    staticClass: "el-icon-caret-bottom"
  })]), _c("el-dropdown-menu", {
    staticClass: "user-dropdown",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    nativeOn: {
      click: function ($event) {
        return _vm.changePassword.apply(null, arguments);
      }
    }
  }, [_c("span", {
    staticStyle: {
      display: "block"
    }
  }, [_vm._v(_vm._s(_vm.$t("login.change_password")))])]), _c("el-dropdown-item", {
    nativeOn: {
      click: function ($event) {
        return _vm.logout.apply(null, arguments);
      }
    }
  }, [_c("span", {
    staticStyle: {
      display: "block"
    }
  }, [_vm._v(_vm._s(_vm.$t("login.logout")))])])], 1)], 1)], 1), _c("edit-dialog", {
    attrs: {
      visible: _vm.changePwdVisible,
      title: _vm.$t("login.change_password"),
      object: _vm.changePwdForm,
      "can-confirm": form => {
        return form.old_password != null && form.old_password.length >= 5 && form.new_password != null && form.new_password.length >= 5;
      },
      "label-position": "left",
      width: 520
    },
    on: {
      confirm: _vm.doChangePwd,
      close: function ($event) {
        _vm.changePwdVisible = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProp) {
        return [_c("el-form-item", {
          attrs: {
            "label-width": "100px",
            label: _vm.$t("login.old_password"),
            required: true
          }
        }, [_c("el-input", {
          attrs: {
            placeholder: _vm.$t("login.old_password"),
            clearable: "",
            disabled: slotProp.loading,
            "show-password": true
          },
          model: {
            value: slotProp.form.old_password,
            callback: function ($$v) {
              _vm.$set(slotProp.form, "old_password", $$v);
            },
            expression: "slotProp.form.old_password"
          }
        })], 1), _c("el-form-item", {
          attrs: {
            "label-width": "100px",
            label: _vm.$t("login.new_password"),
            required: true
          }
        }, [_c("el-input", {
          attrs: {
            placeholder: _vm.$t("login.new_password"),
            clearable: "",
            disabled: slotProp.loading,
            "show-password": true
          },
          model: {
            value: slotProp.form.new_password,
            callback: function ($$v) {
              _vm.$set(slotProp.form, "new_password", $$v);
            },
            expression: "slotProp.form.new_password"
          }
        })], 1)];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };